import styled from "styled-components";
import { Button } from "../../styles";
import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from "react-toastify";

const ContactForm = () => {

      const [formField, setformField] = useState({
            name: '', email: '', subject: '', message: ''
      });
      const [loading, setLoading] = useState(false);
      const [serviceId] = useState(process.env.REACT_APP_SERVICE_ID);
      const [templateId] = useState(process.env.REACT_APP_TEMPLATE_ID);
      const [publicKey] = useState(process.env.REACT_APP_PUBLIC_KEY);
      const form = useRef();

      // Form Fields
      function handleChange(e) {
            setformField((inputs) => ({
                  ...inputs,
                  [e.target.name]: e.target.value,
            }));
      }
      // Form Field


      function clearForm() {
            setformField({
                  name: '', email: '', subject: '', message: ''
            });
      }
      const submitFn = (e) => {
            e.preventDefault();
            setLoading(true);

            emailjs.sendForm(serviceId, templateId, form.current, publicKey)
                  .then(() => {
                        clearForm();
                        toast.success('message sent successfully 😁');
                        setLoading(false);
                  }, () => {
                        toast.warn('message not sent, try again... 😔');
                        setLoading(false);
                  });
      };


      return (
            <StyledContactForm
                  data-aos="flip-right"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
            >

                  <p className="fw-bold mb-4">
                        Send me a message 🚀
                  </p>
                  <StyledForm ref={form} onSubmit={(e) => submitFn(e)}>

                        <div className="col-lg-12">
                              <StyledInput
                                    type="text"
                                    name="name"
                                    placeholder="Fullname *"
                                    onChange={handleChange}
                                    value={formField?.name}
                                    className="input-style"
                                    required
                              />
                        </div>

                        <div className="col-lg-12">
                              <StyledInput
                                    type="email"
                                    name="email"
                                    placeholder="Email address *"
                                    onChange={handleChange}
                                    value={formField?.email}
                                    className="input-style"
                                    required
                              />
                        </div>

                        <div className="col-lg-12">
                              <StyledInput
                                    type="text"
                                    name="subject"
                                    placeholder="Subject *"
                                    onChange={handleChange}
                                    value={formField?.subject}
                                    className="input-style"
                                    required
                              />
                        </div>


                        <div className="col-lg-12">
                              <StyledTextarea
                                    type="text"
                                    name="message"
                                    placeholder="Type your message here ...*"
                                    onChange={handleChange}
                                    value={formField?.message}
                                    className="input-style"
                                    rows='4'
                                    required
                              />
                        </div>
                        <div className="col-lg-12">
                              <Button
                                    disabled={loading}
                                    className="btn pry light-text m-0 w-100"
                              >
                                    {loading ? 'loading...' : "Submit"}
                              </Button>
                        </div>


                  </StyledForm>
            </StyledContactForm>
      );
};

export default ContactForm;

const StyledContactForm = styled.section`
      

`;

const StyledForm = styled.form`

`;

export const StyledLabel = styled.label`
margin:0;
color: var(--grey-bold-color);
font-size:20px; 
`;


const StyledInput = styled.input`
      
   width: 100%;
    padding: 12px 10px;
    margin: 8px 0;
    display: inline-block;
    border: 0;
    border-bottom: 1px solid var(--grey-bold-color);
    box-sizing: border-box;
    border-radius:0 !important;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    color: var(--dark-color);
    background-color: transparent;
  transition: 0.5s ease !important;
       :hover,:focus{
       border: 0!important;
       border-bottom: 1px solid var(--sec-color) !important;
     
        }
        ::placeholder{
       font-size: 15px;
       font-weight: 400;    
        } 

        :disabled{
        color: var(--grey-bold-color);
    }

`;


export const StyledTextarea = styled.textarea`
 width: 100%;
    padding: 12px 10px;
    margin: 8px 0;
    display: inline-block;
    border: 0;
    border-bottom: 1px solid var(--grey-bold-color);
    box-sizing: border-box;
    border-radius:0 !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 24px;
    color: var(--dark-color);
    background-color: transparent;
    resize: none;
  transition: 0.5s ease !important;

  
  :hover,:focus{
      border: 0!important;
       border-bottom: 1px solid var(--sec-color) !important;    }
    
    ::placeholder{
        color: var(--grey-bold-color);
    } 
  
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  appearance: none !important;
}
`;