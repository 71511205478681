import styled from "styled-components";
import { ContainerBg, SubTitle, Title } from "../home/Home.styled";
import { testimonials } from "../../utils/data";
import { Carousel } from "react-bootstrap";

const Testimonials = () => {

      const { title, subtitle, content, slides } = testimonials;


      return (
            <StyledTestimonials>
                  <ContainerBg
                        data-aos="fade-up"
                        data-aos-duration="1400"
                  >
                        <SubTitle>
                              {subtitle}
                        </SubTitle>
                        <Title>
                              {title}
                        </Title>
                        <p>
                              {content}
                        </p>
                        <Carousel
                              indicators={false}
                              controls={true}
                              interval={2000}
                        >
                              {slides.map(({ name, title, content }, idx) => (
                                    <Carousel.Item key={idx}>
                                          <div
                                                className='testimonial'
                                                key={idx}>
                                                <div className="testimonials py-3 px-4 ">
                                                      <span className="display-3">"</span>
                                                      <br />
                                                      <p className='m-0'>
                                                            {content}
                                                      </p>
                                                      <br />
                                                      <p className="display-3 text-end m-0">"</p>
                                                </div>
                                                <div className="mt-5 text-center">
                                                      <h4 className=" m-0">
                                                            {name}
                                                      </h4>
                                                      <small className="">
                                                            {title}
                                                      </small>
                                                </div>
                                          </div>
                                    </Carousel.Item>
                              ))}
                        </Carousel>
                  </ContainerBg>
            </StyledTestimonials>
      );
};

export default Testimonials;


const StyledTestimonials = styled.section`


  .testimonial {
     padding: 40px;
  }
  
  
  .testimonials {
        border-radius: 20px 20px 0px 20px;
        background: var(--grey-color);
        color: var(--light-color);
        /* shadow_2 */
        box-shadow: 2px 2px 50px 0px #ffffff7e;
      }
      
      @media screen and (max-width: 580px) {
            
            .testimonial {
               padding: 40px 10px;
            }
 }

`;