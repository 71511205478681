import styled, { keyframes } from "styled-components";
import { ContainerBg, Content, SubTitle, Title } from "./Home.styled";
import { myClients } from "../../utils/data";
import { Fragment } from "react";


const Clients = () => {

      const { title, subtitle, content, client } = myClients;

      return (
            <StyledClients>
                  <ContainerBg
                        data-aos="fade-up"
                        data-aos-duration="1300"
                  >
                        <SubTitle>
                              {subtitle}
                        </SubTitle>
                        <Title>
                              {title}
                        </Title>
                        <Content className="mb-5">
                              {content}
                        </Content>
                        <marquee behavior="smooth" direction="left" scrollamount="7">


                              {Array.from({ length: 30 }).map((_, index) => (
                                    <Fragment key={index}>
                                          {client.map((img, i) =>
                                                <Image src={img} alt="Client" key={i} />
                                          )}
                                    </Fragment>
                              )
                              )}
                        </marquee>
                  </ContainerBg>
            </StyledClients>
      );
};

export default Clients;


const StyledClients = styled.section`
      
/* overflow: hidden !important; */
      
`;

const Image = styled.img`
  transition: filter 0.5s ease-out; 
  transition: ease 0.7s !important;
  /* height:70px; */
  width:100px;
  margin:0 40px;
  border-radius: 10px;

  /* Apply the grayscale filter by default */
  filter: grayscale(100%);

  /* Remove the grayscale filter on hover */
  &:hover {
  transform: scale(1.1) !important;
    filter: grayscale(0%);
  }
`;
