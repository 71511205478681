import styled from "styled-components";
import ProjectInfoHeader from "../components/project/projectInfo/ProjectInfoHeader";
import ProjectInfoRole from "../components/project/projectInfo/ProjectInfoRole";
import ProjectInfoBody from "../components/project/projectInfo/ProjectInfoBody";
import { useNavigate, useParams } from "react-router-dom";
import { allProjects } from "../utils/data";
import WorkTogether from "../components/WorkTogether";
import HomeWrapper from "../layout/HomeWrapper";
import { useEffect } from "react";


const ProjectInfo = () => {

  const params = useParams();
  const navigate = useNavigate();

  const { id } = params;

  const [project] = allProjects.filter((item) => item.id.includes(id));

  useEffect(() => {
    const checkId = allProjects.find((item) => item.id.includes(id));
    if (!checkId) {
      navigate('/404');
    }
  }, [id]);


  return (
    <HomeWrapper
      title={`${ project?.title } || Hammed Akinyemi\'s Portfolio`}
    >
      <StyledProjectInfo>
        <div className="container-fluid">
          <ProjectInfoHeader {...project} />
          <ProjectInfoRole {...project} />
          <ProjectInfoBody {...project} />
          <WorkTogether />
        </div>
      </StyledProjectInfo>
    </HomeWrapper>

  );
};

export default ProjectInfo;

const StyledProjectInfo = styled.main`

`;
