import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import ErrorPage from '../pages/ErrorPage';
import About from '../pages/About';
import Contact from '../pages/Contact';
import ProjectPage from '../pages/ProjectPage';
import ProjectInfo from '../pages/ProjectInfo';


const Router = () => {
    return (
        <>
            <Routes>

                {/* Landing */}
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />

                <Route path="/contact" element={<Contact />} />

                <Route path='projects'>
                    <Route index element={<ProjectPage />} />
                    <Route exact path=":id" element={<ProjectInfo />} />


                </Route>

                {/* Error */}
                <Route path="*" element={<ErrorPage />} />

            </Routes>
        </>
    );
};

export default Router;