import styled from "styled-components";
import { ContainerBg, SubTitle, Title } from "./Home.styled";
import { allProjects, selectedProjects } from "../../utils/data";
import Project from "../project/Project";
import { Button } from "../../styles";
import { ICON_ARROW_UP_RIGHT } from "../../utils/icons";
import { Link } from "react-router-dom";


const SelectedProject = () => {
      const { subtitle, title } = selectedProjects;

      return (
            <StyledSelectedProject>
                  <ContainerBg
                        data-aos="fade-up"
                        data-aos-duration="1300"
                  >
                        <SubTitle>
                              {subtitle}
                        </SubTitle>
                        <Title>
                              {title}
                        </Title>

                        <Link to="/projects">
                              <Button className="btn light-text">
                                    projects
                                    <span className="ms-2">
                                          {ICON_ARROW_UP_RIGHT}
                                    </span>
                              </Button>
                        </Link>
                  </ContainerBg>
                  <div className="row justify-content-between mt-5">
                        {allProjects?.filter((e) => e.selected === true).map((item) =>
                              <Project key={item.id} {...item} />
                        )}
                  </div>

            </StyledSelectedProject>
      );
};

export default SelectedProject;


const StyledSelectedProject = styled.section`
      margin: 4rem 0 2rem;
     
      .image-container{
            padding: 5rem 3rem;
            background-color: var(--grey-color);
      }   

      .image{
            width:100%;
            height:300px;
      }
      img {
            width:100%;
            height:100%;
            margin:auto;
            object-fit:cover;
      }


`;