import styled from "styled-components";
import { aboutMe } from "../../utils/data";
import { ContainerBg } from "../home/Home.styled";

const AboutMe = () => {
      return (
            <StyledAboutMe>
                  <ContainerBg
                        data-aos="fade-up"
                        data-aos-duration="1400"
                  >
                        <p>
                              {
                                    aboutMe.map((data, i) =>
                                          <p className="" key={i}>
                                                {data}
                                          </p>
                                    )
                              }

                        </p>
                  </ContainerBg>
            </StyledAboutMe>
      );
};

export default AboutMe;


const StyledAboutMe = styled.section`
      margin: 4rem 0;

p{
margin: 1.8rem 0;
text-align:justify;
}


`;