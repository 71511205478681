import styled from "styled-components";
import Hero from "../components/home/Hero";
import HomeAbout from "../components/home/HomeAbout";
import SelectedProject from "../components/home/SelectedProject";
import Clients from "../components/home/Clients";
import WorkTogether from "../components/WorkTogether";
import HomeWrapper from "../layout/HomeWrapper";


const Home = () => {

  return (
    <HomeWrapper>
      <StyledHome>
        <div className="container-fluid">
          <Hero />
          <HomeAbout />
          <SelectedProject />
          <Clients />
          <WorkTogether />

        </div>
      </StyledHome>
    </HomeWrapper>

  );
};

export default Home;

const StyledHome = styled.main`

`;