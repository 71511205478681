import styled from "styled-components";
import { allProjects } from "../../utils/data";
import Project from "./Project";

const ProjectList = () => {

  return (

    <StyledProjectList>
      <div className="container-fluid">
        <div className="row justify-content-around">
          {allProjects.map((item) =>
            <Project key={item.id} {...item} />
          )}
        </div>

      </div>
    </StyledProjectList>
  );
};

export default ProjectList;

const StyledProjectList = styled.section`

`;