import styled from "styled-components";
import ProjectHeader from "../components/project/ProjectHeader";
import ProjectList from "../components/project/ProjectList";
import HomeWrapper from "../layout/HomeWrapper";


const ProjectPage = () => {

  return (
    <HomeWrapper
      title={'Projects || Hammed Akinyemi\'s Portfolio'}
    >
      <StyledProjectPage>
        <div className="container-fluid">
          <ProjectHeader />
          <ProjectList />
        </div>
      </StyledProjectPage>
    </HomeWrapper>

  );
};

export default ProjectPage;

const StyledProjectPage = styled.main`

`;