import Styled from 'styled-components';


export const DashboardWrapper = Styled.section`
  min-height: 100vh;
  padding-left: 250px !important;
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
  background-color: var(--light-color) !important;

  @media screen and (max-width: 580px) {
    padding-top: 5rem !important;
    padding-bottom: 7rem !important;
    padding-left: 0 !important;
  }
`;

export const Button = Styled.button`
margin: 0 !important;
border-radius: 5rem !important;
font-size: 17px !important;
font-weight: 500 !important;
letter-spacing: 0px !important;
line-height: 24px !important;
padding: 12px 32px !important;
text-transform: lowercase !important;

 background: var(--dark-bold-color) !important;
color: var(--light-color) !important;

:hover, :focus{
color: ;
 background: var(--dark-color) !important;
}
`;


export const ErrorPageMain = Styled.main`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 70vh;
    justify-content: center;
    overflow: hidden;
    color: var(--dark-color);
  `;




