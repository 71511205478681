import React, { useContext } from 'react';
import { useLocalStorage } from '../utils/useLocalStorage';

export const ThemeContext = React.createContext();

export const ThemeProvider = ({ children }) => {

    // Set Dark Mode
    const [darkTheme, setdarkTheme] = useLocalStorage('theme');


    const switchTheme = () => {
        const newTheme = darkTheme === 'light' ? 'dark' : 'light';
        setdarkTheme(() => newTheme);
    };
    // Set Dark Mode

    return (
        <ThemeContext.Provider value={{
            darkMode: { darkTheme, switchTheme, darkTheme, setdarkTheme }
        }}>
            {children}
        </ThemeContext.Provider>
    );
};

// make sure use
export const useThemeContext = () => {
    return useContext(ThemeContext);
};
