import styled from "styled-components";
import { workTogether } from "../utils/data";
import { ContainerBg, Content, SubTitle, Title } from "./home/Home.styled";
import { Link } from "react-router-dom";
import { ICON_ARROW_UP_RIGHT } from "../utils/icons";
import { Button } from "../styles";
import { emoji_hard } from "../assets";



const WorkTogether = () => {


      const { subtitle, title, content } = workTogether;

      return (
            <StyledWork>
                  <ContainerBg
                        data-aos="fade-up"
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos-duration="1500"
                  >
                        <div className="row">
                              <div className="col-md-8">
                                    <SubTitle>
                                          {subtitle}
                                    </SubTitle>
                                    <Title className="mb-3 col-md-12">
                                          {title}
                                    </Title>
                                    {/* <Content className="col-md-8">
                              {content}
                        </Content> */}
                                    <Link to="/contact">
                                          <Button className="btn light-text">
                                                message me
                                                <span className="ms-2">
                                                      {ICON_ARROW_UP_RIGHT}
                                                </span>
                                          </Button>
                                    </Link>
                              </div>
                              <div className="col-md-4 text-center mt-3 mt-md-0">
                                    <img src={emoji_hard} alt="Let work together" width="250px" />
                              </div>
                        </div>
                  </ContainerBg>
            </StyledWork>
      );
};

export default WorkTogether;


const StyledWork = styled.section`
      margin: 4rem 0;

`;