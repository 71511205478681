import styled from "styled-components";
import { heroData } from "../../utils/data";
import { ContainerBg, Content, SubTitle } from "./Home.styled";
import Socials from "../Socials";

const Hero = () => {

      const { subtitle, title, content } = heroData;

      return (
            <StyledHero>
                  <ContainerBg
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                  >
                        <SubTitle>
                              {subtitle}
                        </SubTitle>
                        <h1 className="mb-3 col-md-12">
                              {title}
                        </h1>
                        <Content className="col-md-8">
                              {content}
                        </Content>

                        <Socials />

                  </ContainerBg>

            </StyledHero>
      );
};

export default Hero;

const StyledHero = styled.section`

display: flex;
align-items: center;
justify-content: center;
overflow-x: hidden;
margin: 2rem auto 4rem;
padding: 0;
width: 100%;

h1{
      @media screen and (max-width: 576px) {
                font-size: 48px !important;
                line-height: 48px !important;
    }

    @media screen and (min-width: 768px) {
    font-size: 54px !important;
    line-height: 54px !important;
}

    @media screen and (min-width: 992px) {
      font-size: 70px !important;
    line-height: 70px !important;
}

    @media screen and (min-width: 1200px) {
    font-size: 100px !important;
    line-height: 100px !important;
}
}

`;