import { BsPlusCircleFill, BsList, BsBell, BsBellFill, BsFillEyeSlashFill, BsFillEyeFill, BsX, BsFillGiftFill, BsFillPeopleFill, BsArrowRightCircleFill, BsArrowDownCircleFill, BsPeople, BsArrowUpCircleFill, BsShare, BsBank2, BsChevronLeft, BsCreditCard2Back, BsFillShareFill, BsPenFill, BsDownload, BsMoonFill, BsSunFill, BsFacebook, BsTwitterX, BsInstagram, BsLinkedin, BsArrowRight, BsChatLeftTextFill, BsLink45Deg, BsFillPersonFill, BsGithub, BsArrowUpRight } from 'react-icons/bs';
import { IoCheckmark, IoCheckmarkCircleSharp, IoCopy, IoFlashSharp, IoLogOut, IoMailUnread, IoPaperPlane, IoSend } from 'react-icons/io5';
import { MdManageSearch, MdAlternateEmail, MdCameraswitch, MdQrCodeScanner, MdSkipPrevious, MdSkipNext } from "react-icons/md";
import { FaArrowUp, FaBootstrap, FaCss3, FaGitSquare, FaHtml5, FaJsSquare, FaMdb, FaMoneyBill, FaPeopleArrows, FaReact, FaSlackHash, FaWallet } from "react-icons/fa";
import { AiFillDashboard, AiFillExclamationCircle, AiOutlinePaperClip, AiOutlineQrcode } from "react-icons/ai";
import { CgData } from "react-icons/cg";
import { GiCheckMark, GiCrossMark, GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { SiStyledcomponents, SiTailwindcss, SiNextdotjs } from "react-icons/si";
import { PiArrowFatLinesUpFill } from "react-icons/pi";



const ICON_PLUS = <BsPlusCircleFill />;
const ICON_MARK = <IoCheckmark />;
const ICON_MARK_STYLE = <GiCheckMark />;
const ICON_MARK_FILL = <IoCheckmarkCircleSharp />;
const ICON_LIST = <BsList />;
const ICON_BELL = <BsBell />;
const ICON_BELL_FILL = <BsBellFill />;
const ICON_PLANE = <IoPaperPlane />;
const ICON_EYE_FILL = <BsFillEyeFill />;
const ICON_EYE_SLASH_FILL = <BsFillEyeSlashFill />;
const ICON_SEARCH = <MdManageSearch />;
const ICON_X = <BsX />;
const ICON_X_STYLE = <GiCrossMark />;
const ICON_GIFT_FILL = <BsFillGiftFill />;
const ICON_PEOPLE_FILL = <BsFillPeopleFill />;
const ICON_PEOPLE = <BsPeople />;
const ICON_MONEY_BILL = <FaMoneyBill />;
const ICON_PEOPLE_ARROW = <FaPeopleArrows />;
const ICON_WALLET = <FaWallet />;
const ICON_DASHBOARD = <AiFillDashboard />;
const ICON_ARROW_RIGHT_CIRCLE = <BsArrowRightCircleFill />;
const ICON_ARROW_DOWN_CIRCLE = <BsArrowDownCircleFill />;
const ICON_ARROW_UP_CIRCLE = <BsArrowUpCircleFill />;
const ICON_ARROW_UP = <FaArrowUp />;
const ICON_ARROW_LEFT = <BsChevronLeft />;
const ICON_ARROW_RIGHT = <BsArrowRight />;
const ICON_ARROW_UP_RIGHT = <BsArrowUpRight />;
const ICON_DATA = <CgData />;
const ICON_SHARE = <BsShare />;
const ICON_SAVE = <BsDownload />;
const ICON_SHARE_FILL = <BsFillShareFill />;
const ICON_EXCLAMATION = <AiFillExclamationCircle />;
const ICON_BANK = <BsBank2 />;
const ICON_AT = <MdAlternateEmail />;
const ICON_QR = <AiOutlineQrcode />;
const ICON_CARD = <BsCreditCard2Back />;
const ICON_PEN = <BsPenFill />;
const ICON_HASH = <FaSlackHash />;
const ICON_COPY = <IoCopy />;
const ICON_LOGOUT = <IoLogOut />;
const ICON_SWITCH_CAMERA = <MdCameraswitch />;
const ICON_SCAN = <MdQrCodeScanner />;
const ICON_RECEIVE_MONEY = <GiReceiveMoney />;
const ICON_PAY_MONEY = <GiPayMoney />;
const ICON_SEND = <IoSend />;
const ICON_PAPER_CLIP = <AiOutlinePaperClip />;
const ICON_MOON = <BsMoonFill />;
const ICON_SUN = <BsSunFill />;
const ICON_FACEBOOK = <BsFacebook />;
const ICON_TWITTER = <BsTwitterX />;
const ICON_INSTAGRAM = <BsInstagram />;
const ICON_LINKEDIN = <BsLinkedin />;
const ICON_GITHUB = <BsGithub />;
const ICON_EMAIL = <IoMailUnread />;
const ICON_CHAT = <BsChatLeftTextFill />;
const ICON_LINK = <BsLink45Deg />;
const ICON_FLASH = <IoFlashSharp />;
const ICON_USER = <BsFillPersonFill />;
const ICON_REACT = <FaReact />;
const ICON_HTML = <FaHtml5 />;
const ICON_CSS = <FaCss3 />;
const ICON_GIT = <FaGitSquare />;
const ICON_STYLED_COMPONENTS = <SiStyledcomponents />;
const ICON_BOOTSTRAP = <FaBootstrap />;
const ICON_JAVASCRIPT = <FaJsSquare />;
const ICON_TAILWIND = <SiTailwindcss />;
const ICON_MDB = <FaMdb />;
const ICON_NEXT = <MdSkipNext />;
const ICON_PREV = <MdSkipPrevious />;
const ICON_NEXTJS = <SiNextdotjs />;
const ICON_ARROW_LINE_UP = <PiArrowFatLinesUpFill />;





export {
    ICON_PLUS,
    ICON_MARK,
    ICON_MARK_STYLE,
    ICON_MARK_FILL,
    ICON_BELL,
    ICON_BELL_FILL,
    ICON_LIST,
    ICON_PLANE,
    ICON_EYE_FILL,
    ICON_EYE_SLASH_FILL,
    ICON_SEARCH,
    ICON_X,
    ICON_X_STYLE,
    ICON_GIFT_FILL,
    ICON_PEOPLE_FILL,
    ICON_PEOPLE,
    ICON_MONEY_BILL,
    ICON_PEOPLE_ARROW,
    ICON_WALLET,
    ICON_DASHBOARD,
    ICON_ARROW_RIGHT_CIRCLE,
    ICON_ARROW_DOWN_CIRCLE,
    ICON_ARROW_UP_CIRCLE,
    ICON_ARROW_UP,
    ICON_ARROW_UP_RIGHT,
    ICON_DATA,
    ICON_SHARE,
    ICON_SAVE,
    ICON_SHARE_FILL,
    ICON_EXCLAMATION,
    ICON_BANK,
    ICON_AT,
    ICON_QR,
    ICON_ARROW_LEFT,
    ICON_CARD,
    ICON_HASH,
    ICON_COPY,
    ICON_LOGOUT,
    ICON_PEN,
    ICON_SWITCH_CAMERA,
    ICON_SCAN,
    ICON_RECEIVE_MONEY,
    ICON_PAY_MONEY,
    ICON_SEND,
    ICON_PAPER_CLIP,
    ICON_MOON,
    ICON_SUN,
    ICON_FACEBOOK,
    ICON_INSTAGRAM, ICON_LINKEDIN, ICON_TWITTER, ICON_EMAIL,
    ICON_GITHUB,
    ICON_ARROW_RIGHT,
    ICON_CHAT,
    ICON_LINK,
    ICON_FLASH,
    ICON_USER,
    ICON_REACT,
    ICON_HTML,
    ICON_CSS,
    ICON_JAVASCRIPT,
    ICON_GIT,
    ICON_BOOTSTRAP,
    ICON_STYLED_COMPONENTS,
    ICON_TAILWIND,
    ICON_MDB,
    ICON_NEXT,
    ICON_PREV,
    ICON_NEXTJS,
    ICON_ARROW_LINE_UP
};