import styled from "styled-components";
import { ContainerBg, Title } from "../../home/Home.styled";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import { ICON_NEXT, ICON_PREV } from "../../../utils/icons";


const ProjectInfoBody = ({ body, link, prev, next }) => {

      return (

            <StyledProjectInfoBody>
                  <ContainerBg
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                  >
                        {body?.map(({ title, body }, i) =>
                              <div className="mb-5" key={i}>
                                    <Title>
                                          {title}
                                    </Title>
                                    <p className="text-break mt-2"
                                          dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(body)
                                          }}
                                    />
                              </div>
                        )}
                        <p>Ensure you check the website <a href={`https://${ link }`} target="_blank" rel="noopener noreferrer" className="project-link">{link} </a>
                        </p>
                        <p>
                              Thank you!

                        </p>


                        <div className="d-flex justify-content-between mt-4">
                              {prev &&
                                    <Link to={`/projects/${ prev }`} className="project-link"> {ICON_PREV} Prev </Link>
                              }
                              {next &&
                                    <Link to={`/projects/${ next }`} className={`${ !prev && 'ms-auto' } project-link`}>Next {ICON_NEXT} </Link>
                              }

                        </div>
                  </ContainerBg>
            </StyledProjectInfoBody>
      );
};

export default ProjectInfoBody;

const StyledProjectInfoBody = styled.main`

margin: 2rem auto 5rem;

p {
      text-align: justify;
}

.project-link {
      color: var(--sec-color) !important;
      text-decoration: underline !important;
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
`;
