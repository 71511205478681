import styled from "styled-components";
import { ContainerBg, SubTitle, Title } from "../home/Home.styled";
import { contactHeader } from "../../utils/data";
import DOMPurify from "dompurify";


const ContactHeader = () => {

      const { title, subtitle } = contactHeader;

      return (
            <StyledContactHeader>
                  <ContainerBg
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                  >
                        <SubTitle>
                              {subtitle}
                        </SubTitle>
                        <Title
                              dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(title)
                              }}
                        />

                  </ContainerBg>
            </StyledContactHeader>
      );
};

export default ContactHeader;

const StyledContactHeader = styled.section`
margin: 2rem auto 4rem;

`;