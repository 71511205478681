import { Link } from 'react-router-dom';
import { Button, ErrorPageMain } from '../styles';
import HomeWrapper from '../layout/HomeWrapper';
import { ContainerBg } from '../components/home/Home.styled';
import { ICON_ARROW_UP_RIGHT } from '../utils/icons';

const ErrorPage = () => {
  return (
    <>
      <HomeWrapper
        title={'404 || Hammed Akinyemi\'s Portfolio'}
      >
        <ErrorPageMain className="">
          <div className="container">
            <div className="col-md-7 mx-auto text-center ">
              <ContainerBg className="shadow br-md py-5 container"
                data-aos="zoom-in"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-duration="1500"
              >
                <h1 className="mb-1 display-1 sec-text">
                  404
                </h1>
                <h5 className="sec-text fw-light mb-5"> Page not found. </h5>
                <Link to="/">
                  <Button className="btn light-text mt-3">
                    go home
                    <span className="ms-2">
                      {ICON_ARROW_UP_RIGHT}
                    </span>
                  </Button>
                </Link>

              </ContainerBg>
            </div>

          </div>
        </ErrorPageMain>
      </HomeWrapper>

    </>
  );
};

export default ErrorPage;