import styled from "styled-components";
import Socials from "../Socials";
import { ContainerBg, SubTitle, Title } from "../home/Home.styled";
import { projectHeader } from "../../utils/data";



const ProjectHeader = () => {

      const { title, subtitle, content } = projectHeader;
      return (
            <StyledProjectHeader>
                  <ContainerBg
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                  >
                        <div className="">
                              <SubTitle>
                                    {subtitle}
                              </SubTitle>
                              <Title>
                                    {title}
                              </Title>
                        </div>
                        <p className="">
                              {content}
                        </p>
                  </ContainerBg>

            </StyledProjectHeader>
      );
};

export default ProjectHeader;


const StyledProjectHeader = styled.section`
margin: 2rem auto 4rem;

      img {
            border-radius: 1rem;
      }

      `;