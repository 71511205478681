import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, Zoom } from 'react-toastify';
import ScrollToTop from './utils/ScrollToTop';
import { ThemeProvider } from './context/theme_context';
import App from './App';
import 'jquery';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import './index.css';
import './styles/app.css';
import "react-toastify/dist/ReactToastify.css";
import { Analytics } from "@vercel/analytics/react";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider>
        <ScrollToTop />
        <App />
        <ToastContainer
          hideProgressBar={true}
          autoClose={2000}
          // theme="colored"
          newestOnTop
          transition={Zoom}
        />
      </ThemeProvider>
    </BrowserRouter>
    <Analytics />
  </React.StrictMode>
);
// const helmet = Helmet.renderStatic();

