import styled from "styled-components";
import { ContainerBg, SubTitle, Title } from "../../home/Home.styled";
import { Link } from "react-router-dom";
import { ICON_ARROW_LEFT } from "../../../utils/icons";


const ProjectInfoHeader = ({ title, subtitle, img }) => {

      return (

            <StyledProjectInfoHeader>
                  <ContainerBg
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                  >
                        <div className="">
                              <Link
                                    to={-1}
                                    className="d-flex align-items-center"
                              >
                                    <span className=" text-decoration-underline sec-text ">
                                          {ICON_ARROW_LEFT}
                                    </span>
                                    <SubTitle className="mb-0 mt-1">
                                          {title}
                                    </SubTitle>
                              </Link>
                        </div>

                        <Title>
                              {subtitle}
                        </Title>
                        <img src={img} alt="Title" width="100%" className="br-md" />
                  </ContainerBg>
            </StyledProjectInfoHeader>
      );
};

export default ProjectInfoHeader;

const StyledProjectInfoHeader = styled.main`

margin: 2rem auto 4rem;

`;
