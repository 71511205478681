import styled from "styled-components";
import ContactHeader from "../components/contact/ContactHeader";
import ContactForm from "../components/contact/ContactForm";
import ContactDetails from "../components/contact/ContactDetails";
import { ContainerBg } from "../components/home/Home.styled";
import HomeWrapper from "../layout/HomeWrapper";



const Contact = () => {

  return (
    <HomeWrapper
      title={'Contact || Hammed Akinyemi\'s Portfolio'}
    >
      <StyledContact>
        <div className="container-fluid">
          <ContactHeader />
          <ContainerBg
            data-aos="fade-up"
            data-aos-duration="1400"
          >
            <div className="row justify-content-between">
              <div className="col-md-5 mb-5 mb-md-0">
                <ContactDetails />
              </div>
              <div className="col-md-6">
                <ContactForm />
              </div>
            </div>
          </ContainerBg>
        </div>
      </StyledContact>
    </HomeWrapper>

  );
};

export default Contact;

const StyledContact = styled.main`

`;