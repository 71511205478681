import styled from "styled-components";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet";
import ScrollToTopButton from "../components/ScrollToTop";

const HomeWrapper = ({ children, title }) => {
      return (
            <>
                  <Helmet>
                        <meta charSet="utf-8" />
                        <title>{title || 'Hammed Akinyemi\'s Portfolio || Frontend Engineer, Software Developer, Web Designer'}</title>

                  </Helmet>
                  <Navbar />
                  {children}
                  <Footer />
                  <ScrollToTopButton />
            </>
      );
};

export default HomeWrapper;
const StyledHome = styled.div`
      
padding-top:2.8rem !important;

`;