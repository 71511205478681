import styled from "styled-components";
import { ICON_EYE_FILL, ICON_LINK } from "../../utils/icons";
import { Link } from "react-router-dom";
import { ContainerBg, Content } from "../home/Home.styled";



const Project = ({ id, img, title, subtitle, comingSoon }) => {

  return (

    <StyledProject className="col-md-6 col-lg-4 mb-4 ">
      <ContainerBg className="project px-3 py-4 h-100"
        data-aos="flip-up"
        data-aos-duration="1000"
      >
        <div className="card-body">
          <div className="d-flex flex-wrap">
            <h3 className="my-1 me-2">
              {title}
            </h3>
            {comingSoon &&
              <div className="light-text d-flex align-items-center">
                <p className=" m-0 br-lg dark-bold-bg px-2 py-0 font-monospace d-flex align-items-center " style={{ fontSize: '10px', height: '15px' }}>
                  coming soon
                </p>
              </div>
            }
          </div>

          <Content className="">
            {subtitle}
          </Content>
        </div>
        <div class="bg-image ripple hover-overlay hover-zoom br-md">
          <img src={img} alt={title} width='100%' />
          <div class="mask">
            {!comingSoon &&
              <Link to={`/projects/${ id }`} className="text-center">
                <h4 className="m-0">
                  {ICON_EYE_FILL}
                </h4>
                <small className="m-0"> view project</small>
              </Link>
            }
          </div>
        </div>

      </ContainerBg>
    </StyledProject>
  );
};

export default Project;

const StyledProject = styled.div`


.project {
  /* padding: 1rem;
  border: 1px dotted var(--dark-color);
  border-radius: 1rem; */
  


  
  .mask{
    background-color: #000000b3;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items:center;

  }
  
            .image{
                  width:auto;
                  height:100%;
                  

                  img {
                    width:100%;
                    height:100%;
                    margin:auto;
                    object-fit:cover;
                  }
      }   

      a {
        color: #fff;
      }

}
`;