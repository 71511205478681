import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import { emoji_logo } from "../assets";
import { ICON_LIST, ICON_MOON, ICON_SUN } from "../utils/icons";
import { navLink } from "../utils/data";
import { useThemeContext } from "../context/theme_context";


const Navbar = () => {

      const {
            darkMode: { darkTheme, switchTheme }
      } = useThemeContext();



      return (
            <StyledNavbar className="container-fluid">
                  <nav className='navbar navbar-expand-md shadow-none' id="navbar">
                        <div className="container">
                              <Link className="navbar-brand" to="/">
                                    <img src={emoji_logo} alt="HammedAkin" width='70px' />
                              </Link>
                              <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-mdb-toggle="collapse"
                                    data-mdb-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                              >
                                    <span className="navbar-toggler-icon toggler">
                                          <h3
                                                className={`bi bi-list dark-text h3`}
                                          >
                                                {ICON_LIST}
                                          </h3>
                                    </span>
                              </button>
                              <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">

                                    <ul className="navbar-nav text-center">
                                          {navLink.map(({ name, link }, i) =>
                                                <li className="nav-item" key={i}>
                                                      <NavLink to={link}>
                                                            {name}
                                                      </NavLink>
                                                </li>
                                          )}
                                    </ul>

                                    <div className="navbar-nav">
                                          <li className="nav-item text-center">
                                                <a
                                                      className={`bi bi-list dark-text h3`}
                                                      onClick={() => switchTheme()}

                                                >
                                                      {darkTheme === "dark" ? ICON_SUN : ICON_MOON}
                                                </a>
                                          </li>
                                    </div>
                              </div>
                        </div>
                  </nav>
            </StyledNavbar>
      );
};

export default Navbar;

const StyledNavbar = styled.section`
     
     padding-top: 2rem !important;
     
     /* Navbar */
     nav {
           transition: 0.5s ease !important;
           padding: 1.5rem 0 !important;
           
           border-radius: 1.5rem; 
           background: var(--container-bg); 
      }
      
      /* When Scrolled */
.nav-scrolled {
    margin-top: -1rem !important;
    margin-left: .8rem !important;
    margin-right: .8rem !important;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;

      backdrop-filter: blur(6px);
      padding: 1rem 0 !important;
      background: #fffff6d2 !important;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.05) !important;
}

nav.nav-scrolled .nav-item a:hover {
      border: var(--light-bold-color) solid 1px !important;
}
nav.nav-scrolled .nav-item a.active {
  background-color: var(--light-bold-color);
}
/* When Scrolled */

nav .nav-item a:hover {
      border: var(--bg-color) solid 1px !important;
      transition: 0.1s ease !important;
  border-radius: 5rem;
}
nav .nav-item a.active {
  transition: 0.1s ease !important;
  background-color: var(--bg-color);
}

nav button.navbar-toggler {
  background-color: none !important;
}

.navbar-toggler-icon {
  background: none !important;
}

nav .navbar-brand {
  width: 43% !important;
}

nav .nav-item a {
  color: var(--dark-color);
  font-size: 1rem;
  display: block;
  padding: 0.5rem 2.5rem;
  margin: 0 0.1rem;
  border-radius: 5rem;
      border: transparent solid 1px !important;

}

#header {
  background: #0c0b0999;
  border-bottom: 1px solid rgba(12, 11, 9, 0.6);
  transition: all 0.5s;
  z-index: 997;
  padding: 25px 0;
}

/* Navbar */

@media screen and (max-width: 580px) {
  /* Navbar */
  nav .navbar-brand {
    width: 50% !important;
  }

  /* Navbar */
}

`;