import styled from "styled-components";
import { ContainerBg, SubTitle, Title } from "../home/Home.styled";
import { skills } from "../../utils/data";


const Tools = () => {

      const { title, subtitle, content, tools } = skills;

      return (
            <StyledTools>
                  <ContainerBg
                        data-aos="fade-up"
                        data-aos-duration="1500"
                  >
                        <SubTitle>
                              {subtitle}
                        </SubTitle>
                        <Title>
                              {title}
                        </Title>
                        <p className="">
                              {content}
                        </p>


                        <div className="row justify-content-center mt-5">
                              {tools.map(({ title, img }, i) =>
                                    <div className="col-4 col-sm-4 col-lg-2 col-md-2 text-center mb-4" key={i}
                                          data-aos="flip-up"
                                          data-aos-duration="1000"
                                    >
                                          <div className="tools">
                                                <h6 className="hover mx-auto">
                                                      {img}
                                                </h6>
                                                <p className=" ">
                                                      {title}
                                                </p>
                                          </div>
                                    </div>
                              )}
                        </div>
                  </ContainerBg>





            </StyledTools>
      );
};

export default Tools;

const StyledTools = styled.section`
    margin:4rem 0;  

      h6 {
            display: flex;
            align-items: center;
            justify-content:center;
            text-align: center;
            /* margin:1rem; */
            color: var(--light-color) ;
            border-radius: 5rem;
            height:80px;
            width:80px;
            font-size: 2rem !important;
            background: var(--grey-color);
            
            @media screen and (max-width: 576px) {
                  font-size: 1rem;
             height:60px;
             width:60px;
                  
          }
      }
`;