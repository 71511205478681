import styled from "styled-components";

import AboutHeader from "../components/about/AboutHeader";
import AboutMe from "../components/about/AboutMe";
import Tools from "../components/about/Tools";
import Testimonials from "../components/about/Testimonials";
import WorkTogether from "../components/WorkTogether";
import HomeWrapper from "../layout/HomeWrapper";


const About = () => {

  return (
    <HomeWrapper
      title={'About Me || Hammed Akinyemi\'s Portfolio'}
    >
      <StyledAbout>
        <div className="container-fluid">
          <AboutHeader />
          <AboutMe />
          <Tools />
          <Testimonials />
          <WorkTogether />
        </div>
      </StyledAbout>
    </HomeWrapper>

  );
};

export default About;

const StyledAbout = styled.main`

`;