import styled from "styled-components";
import Socials from "../Socials";
import { ContainerBg, SubTitle, Title } from "../home/Home.styled";
import { aboutHeader } from "../../utils/data";



const AboutHeader = () => {

      const { title, subtitle, img } = aboutHeader;
      return (
            <StyledAboutHeader>
                  <ContainerBg
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                  >
                        <div className="row align-items-center">
                              <div className="col-md-8 mb-md-0 mb-5">
                                    <SubTitle>
                                          {subtitle}
                                    </SubTitle>
                                    <Title>
                                          {title}
                                    </Title>

                                    <Socials />
                              </div>
                              <div className="col-md-4">
                                    <img src={img} alt="Avatar" width="100%" />
                              </div>
                        </div>
                  </ContainerBg>

            </StyledAboutHeader>
      );
};

export default AboutHeader;


const StyledAboutHeader = styled.section`
margin: 2rem auto 4rem;

      img {
            border-radius: 1rem;
      }

      `;