import styled from "styled-components";
import { socials } from "../utils/data";
import { Link } from "react-router-dom";



const Socials = () => {
      return (
            <StyledSocials>
                  {
                        socials.map(({ icon, link }, i) =>
                              <Link
                                    type='button'
                                    className="shadow-sm hover"
                                    to={link}
                                    key={i}
                              >
                                    {icon}
                              </Link>
                        )
                  }
            </StyledSocials>
      );
};

export default Socials;

const StyledSocials = styled.div`

       display: flex;
      
      a {
            display: flex;
            align-items: center;
            justify-content:center;
            margin:1rem;
            color: var(--dark-text);
            border-radius: 9999px;
            height:60px;
            width:60px;
            font-size: 1.4rem;
            
            @media screen and (max-width: 576px) {
                  font-size: 1rem;
             height:45px;
             width:45px;
                  
          }
      
      }   
`;