import styled from "styled-components";
import Socials from "../Socials";
import { contactDetails } from "../../utils/data";
import { SubTitle } from "../home/Home.styled";
import DOMPurify from "dompurify";


const ContactDetails = () => {

      const { subtitle, content, contact, } = contactDetails;

      return (
            <StyledContactDetails
                  data-aos="flip-left"
                  data-aos-duration="1000"
                  data-aos-delay="1400"
            >

                  <SubTitle className="mb-4">
                        {subtitle}
                  </SubTitle>
                  <p
                        dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(content)
                        }}
                  />

                  {
                        contact.map(({ title, link, connect }, i) =>
                              <p className="" key={i}>
                                    {title}
                                    <span>
                                          <a href={link}
                                                className="fw-bold dark-text ms-1 text-decoration-underline"
                                          >
                                                {connect}
                                          </a>
                                    </span>
                              </p>
                        )
                  }

                  <Socials />
            </StyledContactDetails>
      );
};

export default ContactDetails;

const StyledContactDetails = styled.section`
      

`;