import styled from "styled-components";
import { homeAbout } from "../../utils/data";
import { ContainerBg, Content, SubTitle, Title } from "./Home.styled";
import { img2 } from "../../assets";
import { Button } from "../../styles";
import { Link } from "react-router-dom";
import { ICON_ARROW_UP_RIGHT } from "../../utils/icons";


const HomeAbout = () => {

      const { subtitle, title, content } = homeAbout;

      return (
            <StyledHomeAbout>
                  <ContainerBg
                        data-aos="fade-up"
                        data-aos-duration="1300"
                  >
                        <SubTitle>
                              {subtitle}
                        </SubTitle>
                        <div className="row">
                              <div className="col-md-8">
                                    <Title>
                                          {title}
                                    </Title>
                                    <Content className="">
                                          {content}
                                    </Content>
                              </div>
                              <div className="col-md-4 text-center hover-zoom">
                                    <img
                                          src={img2}
                                          alt="Avatar"
                                          width='70%'
                                    />
                              </div>
                        </div>
                        <div className=" my-3">
                              <Link to="/about">
                                    <Button className="btn light-text ">
                                          about me
                                          <span className="ms-2">
                                                {ICON_ARROW_UP_RIGHT}

                                          </span>
                                    </Button>
                              </Link>
                        </div>
                  </ContainerBg>

            </StyledHomeAbout>
      );
};

export default HomeAbout;


const StyledHomeAbout = styled.section`
      margin: 4rem 0;

img {
      border-radius: 1rem;
}
`;