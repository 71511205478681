import React from 'react';
import Router from './router/router';
import AOS from "aos";
import 'aos/dist/aos.css';
import { useThemeContext } from './context/theme_context';
import styled from 'styled-components';


AOS.init();


function App() {


  const {
    darkMode: { darkTheme }
  } = useThemeContext();


  return (
    <StyledApp data-theme={darkTheme}>
      <Router />
    </StyledApp>
  );
}
export default App;


const StyledApp = styled.div`
  background-color: var(--bg-color) !important;
  color: var(--grey-color) !important ;
  
`
