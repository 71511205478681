import styled from "styled-components";

export const SubTitle = styled.p`
color: var(--sec-color);
font-size: 0.875em;
margin-bottom: 1rem ;
text-transform: uppercase;
`;


export const Title = styled.h1`
margin-bottom: 1rem ;

  @media screen and (max-width: 576px) {
    font-size: 48px !important;
    line-height: 48px !important;
    }

    @media screen and (min-width: 768px) {
    font-size: 54px !important;
    line-height: 54px !important;
}

    @media screen and (min-width: 992px) {
      font-size: 70px !important;
    line-height: 70px !important;
}

    @media screen and (min-width: 1200px) {
     font-size: 70px !important;
    line-height: 70px !important;
}
`;
export const Content = styled.p`

font-size: 18px !important;
line-height: 30px !important;
  /* @media screen and (max-width: 576px) {
    }

    @media screen and (min-width: 768px) {
    font-size: 25px !important;
    line-height: 40px !important;
}

    @media screen and (min-width: 992px) {
       font-size: 25px !important;
    line-height: 40px !important;
}

    @media screen and (min-width: 1200px) {
     font-size: 25px !important;
    line-height: 40px !important;
} */
`;

export const ContainerBg = styled.div`
    border-radius: 1.5rem; 
  padding: 3rem 2rem ;
  background: var(--container-bg); 
  
  @media screen and (max-width: 576px) {
        padding: 3rem 1rem ;

    }

      
`;