import styled from "styled-components";
import { img1 } from "../../../assets";
import { ContainerBg, SubTitle } from "../../home/Home.styled";
import { ICON_ARROW_UP_RIGHT } from "../../../utils/icons";


const ProjectInfoRole = ({ tools, role, duration, year, link }) => {

      return (

            <StyledProjectInfoRole>
                  <ContainerBg
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                  >
                        <SubTitle>
                              project summary
                        </SubTitle>
                        <div className="row">
                              <div className="col-6 col-md-3 mt-4 mt-sm-0">
                                    <h5 className="">
                                          My Role:
                                    </h5>
                                    {role?.map((item, i) =>
                                          <p className="m-0" key={i}>
                                                {item}
                                          </p>
                                    )}
                              </div>
                              <div className="col-6 col-md-3 mt-4 mt-sm-0">
                                    <h5 className="">
                                          Tools:
                                    </h5>
                                    {tools?.map((item, i) =>
                                          <p className="m-0" key={i}>
                                                {item}
                                          </p>
                                    )}
                              </div>
                              <div className="col-6 col-md-3 mt-4 mt-sm-0">
                                    <h5 className="">
                                          Website:
                                    </h5>
                                    <a href={`https://${ link }`} target="_blank" rel="noopener noreferrer" className="sec-text text-decoration-underline font-monospace">
                                          visit {ICON_ARROW_UP_RIGHT}
                                    </a>
                                    {/* <p className="">
                                                {link}
                                          </p> */}
                              </div>
                              <div className="col-6 col-md-3 mt-4 mt-sm-0">
                                    <h5 className="">
                                          Duration/Year:
                                    </h5>
                                    <p className="">
                                          {duration} / {year}
                                    </p>
                              </div>
                        </div>
                  </ContainerBg>
            </StyledProjectInfoRole>
      );
};

export default ProjectInfoRole;

const StyledProjectInfoRole = styled.main`

margin: 2rem auto 4rem;

`;
